'use es6';

import { bender } from 'legacy-hubspot-bender-context'; // import-eslint-disable-line
import { editorFilesToPrefetchByPackage } from './constants';
const domainPrefix = bender.staticDomainPrefix;
const prefetchProjectAssets = (domain, depPrefix, assets) => {
  assets.forEach(path => {
    const url = `https:${domain}${depPrefix}/${path}`;
    const tag = document.createElement('link');
    tag.rel = 'prefetch';
    tag.href = url;

    // Some debugging that can be turned on via localStorage (and is also on by default via test/prefetcher.html)
    // Using window.enviro instead of import to not accidentally pull in another copy of enviro
    // or deal with enviro in other contexts (like when the prefetcher is used in the HubSpot sprocket
    // menu on a customer's actual page)
    if (window.enviro && window.enviro.debug('page-editor-prefetcher')) {
      tag.onload = () => {
        console.log(`Loaded ${url};`);
      };
      tag.onerror = () => {
        console.error(`Error loading ${url};`);
      };
      console.log(`Prefetching: ${url} by inserting`, tag);
    }
    document.head.appendChild(tag);
  });
};
const prefetch = pathsToPrefetchByPackage => {
  Object.keys(pathsToPrefetchByPackage).forEach(packageKey => {
    const assetPrefix = bender.depPathPrefixes[packageKey];
    const filesToPrefetch = pathsToPrefetchByPackage[packageKey];
    prefetchProjectAssets(domainPrefix, assetPrefix, filesToPrefetch);
  });
};
prefetch(editorFilesToPrefetchByPackage);